<template>
    <UnidadeFederacaoList />
</template>

<script>
    import UnidadeFederacaoList from '../../components/cadastro/UnidadeFederacaoList.vue';

    export default {
        name: 'UnidadeFederacaoPage',
        components: { UnidadeFederacaoList }
    }

</script>
